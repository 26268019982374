import React from "react";
import Logo from "../../assets/logo.png";

const Baseline11Logo = () => {
  return (
    <img
      src={Logo}
      alt="Baseline11"
      style={{ height: 21, width: 100, marginRight: 2 }}
    />
  );
};

export default Baseline11Logo;
